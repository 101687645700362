<template>
  <section class="container-scroller">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card ">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title text-white rounded p-3 mb-5 text-center  "
              style="background-color: #0b5d3f; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
              text-style: none;">
              Remise des produits au patient
            </h3>
            <div class="col-md-12 col-lg-12 mt-3">
              <div class="row">
                <div class="col-md-6 mt-2 col-lg-6">
                  <h4> Liste Des 10 Dernières Venues</h4>
                </div>
                <div class="search-bar col-md-6 col-lg-6">
                  <input @input="getListAndSearchMovments" type="text" v-model="searchTerm" placeholder="Rechercher..." />
                </div>
              </div>

            </div>


            <div v-if="displayedMovments.length > 0" class="table-responsive mt-4 tables">
              <table id="order-listing" ref="myTable" class="table table-bordered">
                <thead>
                  <tr style="background-color: rgb(203, 204, 203)">
                    <th style="width: 5%">#</th>
                    <th style="width: 15%">Date d'arrivé</th>
                    <th style="width: 15%">N° IEP</th>
                    <th style="width: 15%">N° IPP</th>
                    <th style="width: 30%">Nom et Prénom</th>
                    <th style="width: 20%">Téléphone</th>
                  </tr>
                </thead>

                <tr class="row-hover" v-for="(movment, index) in displayedMovments" :key="movment.id"
                  @click="selectRow(index, movment.id)" :class="{ selected: selectedRow == index }">
                  <td>{{ index + 1 }}</td>
                  <td>{{ movment.formatted_arrivaldate }}</td>
                  <td>{{ movment.iep }}</td>
                  <td>{{ movment.ipp }}</td>
                  <td>{{ movment.lastname }} {{ movment.firstname }}</td>
                  <td>{{ movment.phone }}</td>
                </tr>
              </table>
            </div>

            <div v-else class="table-responsive mt-4 ">
              <table id="order-listing" ref="myTable" class="table table-bordered">
                <thead>
                  <tr style="background-color: rgb(216, 218, 216)">
                    <th style="width: 5%">#</th>
                    <th style="width: 15%">Date d'arrivé</th>
                    <th style="width: 15%">N° IEP</th>
                    <th style="width: 15%">N° IPP</th>
                    <th style="width: 30%">Nom et Prénom</th>
                    <th style="width: 20%">Téléphone</th>
                  </tr>
                </thead>
                <tr>
                  <td colspan="6">Aucune donnée trouvée</td>
                </tr>
              </table>
            </div>


            <fieldset class="scheduler-border col-md-12" v-if="medicalActDetails.length > 0">
              <legend class="scheduler-border" style="font-size: medium !important">
                Produits achetés
              </legend>
              <br />
              <div class="table-responsive">
                <table
                  id="order-listing"
                  ref="myTable"
                  class="table table-hover table-bordered"
                >
                  <thead>
                    <tr style="background-color: rgb(216, 218, 216)">
                      <th>#</th>
                      <th>Code</th>
                      <th>Désignation</th>
                      <th>Quantité</th>
  
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(actDetail, index) in medicalActDetails" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ actDetail.code }}</td>
                      <td style="text-align: left">{{ actDetail.name + " " + actDetail.conditioning_unit + " " + actDetail.sales_unit }}</td>
                      <td>{{ actDetail.purchased_quantity }}</td>
                      <td class="text-right" width="50px">
                        <div class="btn-group btn-group-sm" role="group">
                          <button
                            id="btnGroupDrop1"
                            type=""
                            style="padding: 0px 10px"
                            class="btn btn-light dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            &nbsp;
                            <i class="fa fa-tasks" aria-hidden="true"></i> &nbsp;
                          </button>
                          <div
                            class="dropdown-menu action-button-div"
                            aria-labelledby="btnGroupDrop1"
                            style="box-shadow: 0 4px 6px rgb(0 0 0 / 30%)">
                              <button
                                class="dropdown-item text-danger font-weight-bold"
                                @click="makeDestock(actDetail.id, actDetail.patient_movement_details_id, actDetail.purchased_quantity)">
                                <i
                                  class="fa fa-arrow-left"
                                  aria-hidden="true"
                                ></i>
                                Déstocker
                              </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script></script>

<script type="text/javascript">
import $ from "jquery";
import { format } from "date-fns";

import "../../../../node_modules/bootstrap/dist/js/bootstrap.js"; // tres important pour le modal
import "../../../../node_modules/bootstrap/dist/js/bootstrap.min.js"; // tres important pour le modal

import { patientService } from "@/_services/patients_services.js";
import { pourcentagePatient } from "@/_services/pourcentage_indigence_patient_services.js";
import { PriseEnChargePatientService } from "@/_services/priseenchargepatient_services.js";

import { pharmacyService } from "@/_services";

import { factureService } from "@/_services/facture_services.js";
import { especeService } from "@/_services/espece_services.js";
import { useToast, POSITION } from "vue-toastification";
import QRCode from "qrcode";

import "@/asset/js/fedapay_checkout.js?v=1.1.7";

export default {
  name: "create-destock",

  data() {
    return {
      selectedPatient: "",
      isPrinting: false,
      modePayements: [],
      selectedCashRegister:"",
      displayedMovments: [], // Les mouvements à afficher dans le tableau
      searchTerm: "", // Terme de recherche
      destock: {
        id: "",
        patient_movement_details_id: "",
        purchased_quantity: "",
      },

      user: JSON.parse(localStorage.getItem("user")),

      modePayement: "",
      patients: [], // Remplir cette liste avec les patients disponibles
      patientsMove: [], // Remplir cette liste avec les patients disponibles
      patient: {},
      statutCashier:0,

      assurance: "",
      indigence: "",
      assurancePourcentage: null,
      assuranceNumero: null,
      indigencePourcentage: "",
      totalPriseEncharge: "",
      indigencePatients: [],
      indigences: [],
      patientIndigence: null,
      pourcentageIndigence: null,
      prisenchargePourcentage: null,
      assurancepatient: null,
      indigencepatient: "",
      payementId: "",
      montantFedapay: null,
      montantKikiapay: null,
      qrCodeUrl: "",
      feesFedapay: "",
      affectterminals: [],
      listMovments: [],
      referenceFacture: null,
      totalActe: "",
      selectedRow: -1, // Index de la ligne sélectionnée (aucune ligne sélectionnée initialement)
      medicalActDetails: [], // Tableau pour stocker les détails des actes médicaux
      medicalActDetailsFacture: [],

      // pourcentageindigence:"",
      searchQuery: "",
      cashRegisterCashierCurrent: {},
      cashsHistoricalCurrent: {},
      searchQuery1: "",
      anotherParamSearch: "", // Ce paramètre sera utilisé pour la requête

      actesMedicals: [], // Tableau pour stocker l'acte medical en fonction de l'id de l'acte choisi par user
      listactes: [], // Tableau pour stocker l'acte medical en fonction de l'id de l'acte choisi par user
      actesSelectionnes: [], // Tableau pour stocker tous les actes medicaux chaque fois que user clique dessus
  

     

      montantEspece: null,
      statusFedapay: null,
    

      momoData: {
        selectedOptionMode: "",
        nom: "",
        statut: "PENDING",
        prenom: "",
        telephone: "",
        montant: "",
        transaction: null,
      },

      momoDataEspece: {
        montant: "",
      },

      //  readonlyoption: 'autre',
      //  isreadonly: true,

      afficherModal: false,
      //selected: 'A',

      selectedOptionMode: "",

      selected: "",
      options: [
        { item: "F", name: " Feminin" },
        { item: "M", name: " Masculin" },
        //{ item: 'D', name: 'Option C', notEnabled: true },
        // { item: { d: 1 }, name: 'Option D' } this.forms.push({ mode: '', code: '', prix: '', quantite: '', montant: '' });
      ],

      forms: [
        {
          acte: "SA",
          quantite: "",
          code: "",
          prix: "",
          montant: "",
          autre: "",
          readonly: true,
        },
      ],

      showModal: false,

      formErrors: {
        nom: false,
        prenom: false,
        telephone: false,
        patient: false,
        agePatient: false,
        acteMedical: false,
        autreActe: false,
        prix: false,
      },
    };
  },

  methods: {
    makeDestock(product_id, patient_movement_details_id, purchased_quantity)
    {
      this.destock.id = product_id;
      this.destock.patient_movement_details_id = patient_movement_details_id;
      this.destock.purchased_quantity = purchased_quantity;

      pharmacyService.
      destock(this.destock)
      .then((response)=>{
        this.$toast.success(response.data.message, {
            position: POSITION.TOP_RIGHT,
            timeout: 3000,
            bodyStyle: {
              borderRadius: "10px",
              backgroundColor: "#f0f0f0",
              color: "#333",
              fontWeight: "bold",
            },
        });
        console.log(response);
      })
      .catch((error)=>{
        console.log(error.response.data.message);
        this.$toast.error(error.response.data.message,{
              position: "top-right",
              timeout: 3000,
              style: {
                background: "#ff0000", // Couleur de fond rouge pour l'erreur
                color: "#fff", // Couleur du texte
                fontWeight: "bold",
              },
            }
          );
      });
    },
    getHistoricalCurrent() {
      // Si this.searchTerm est vide, obtenez les derniers mouvements par défaut.
      // Sinon, effectuez une recherche en utilisant this.searchTerm.

      factureService
        .getHistoricalCurrent(this.cashRegisterCashierCurrent.cash_registers_id)
        .then((res) => {
          this.cashsHistoricalCurrent = res.data.data;
          console.log("this.cashsHistoricalCurrent ");
          console.log(this.cashsHistoricalCurrent );
          // console.log("this.displayedMovments");
          // console.log(this.displayedMovments);
        })
        .catch((error) => {
          console.error(error.response.data.message);
          this.$toast.error(error.response.data.message,{
              position: "top-right",
              timeout: 3000,
              style: {
                background: "#ff0000", // Couleur de fond rouge pour l'erreur
                color: "#fff", // Couleur du texte
                fontWeight: "bold",
              },
            }
          );
        });
    },

    selectRow(index, movmentId) {
      console.log(movmentId);
      if (this.selectedRow === index) {
        // Si la ligne est déjà sélectionnée, désélectionnez-la
        this.selectedRow = -1;
        this.medicalActDetails = []; 
      } else {
        // Sinon, sélectionnez la ligne
        this.selectedRow = index;

        // console.log(movmentId);
        pharmacyService
        .getProductsMovment(movmentId)
        .then((response) => {
          console.log(response)
          this.medicalActDetails = response.data.data;
          this.medicalActDetailsFacture = response.data.data;
          if(this.medicalActDetails.length == 0){
            this.$toast.error(
              "Aucun produit payé non récupéré trouvé!",
              {
                position: "top-right",
                timeout: 3000,
                style: {
                  background: "#ff0000", // Couleur de fond rouge pour l'erreur
                  color: "#fff", // Couleur du texte
                  fontWeight: "bold",
                },
              }
            );
          }

          console.log(this.medicalActDetails);
        })
        .catch((error) => {
          console.error(error);
        });

        factureService
          .getPatientInfo(movmentId)
          .then((res) => {
            this.patient = res.data.data;

            console.log(" this.patientSelected");
            console.log(this.patient);
          })
          .catch((error) => {
            console.error(error);
          });
        // Appelez la méthode pour récupérer les détails des actes médicaux en utilisant movmentId
      }
    },

    getListMovment() {
      factureService.getListMovment().then((res) => {
        this.listMovments = res.data.data;

        // console.log(this.listMovments);
      });
    },
    getListAndSearchMovments() {
      // Si this.searchTerm est vide, obtenez les derniers mouvements par défaut.
      // Sinon, effectuez une recherche en utilisant this.searchTerm.
      const formData = { search: this.searchTerm };
      factureService
        .searchMovments(formData)
        .then((res) => {
          this.displayedMovments = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },


    fetchPatients() {
      patientService
      .getAllPatients()
      .then((res) => {
        this.patients = res.data.data;
      })
      .catch((error) => {
        console.error(error);
      });
    },

    detail() {
      this.formErrors.patient = false;
      patientService.getPatient(this.selectedPatient).then((res) => {
        this.patient = res.data.data;
      });

      pourcentagePatient
        .getPourcentagePatient(this.selectedPatient)
        .then((res) => {
          this.pourcentageIndigence = res.data.data;

          // console.log(this.pourcentageIndigence);
        })

        .catch((error) => {
          console.error(error);
        });

      PriseEnChargePatientService.getAssurancePatient(this.selectedPatient)
        .then((res) => {
          console.log(res.data.data);
          this.assurancepatient = res.data.data.assurance_nom;
          this.assurancePourcentage = res.data.data.pourcentage_assurance;
          this.assuranceNumero = res.data.data.numero_assurance;
          console.log(this.assurancepatient, this.assurancePourcentage);
        })
        .catch((error) => {
          console.error(error);
        });
    },



    formaterDate(date) {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('fr-FR', options);
  const formattedTime = new Date(date).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
  return `${formattedDate} à ${formattedTime}`;
},
 

    calculateTotalMontantActe() {
      let total = 0;
      for (const form of this.forms) {
        total += form.montant;
      }
      return total;
    },
    calculateTotalAmount() {


      let totalAmount = 0;

      for (const acte of this.medicalActDetailsFacture) {

        totalAmount += acte.medical_acts_qte * Math.round(acte.medical_acts_price);
      }

      this.totalActe = totalAmount;


      if (this.selectedOptionMode == 3) {
        this.totalActe = this.totalActe + this.feesFedapay;
      }

      this.momoData.montant = this.totalActe;
      // console.log("Total Amount:", this.totalActe);
      return this.totalActe;
    },

    calculateTotalMontant() {
      let total = 0;
      let assuranceMontant = 0;
      let indigenceMontant = 0;

      for (const form of this.forms) {
        total += form.montant;
      }

      if (this.assurancePourcentage) {
        assuranceMontant = (this.assurancePourcentage * total) / 100;
      } else {
        assuranceMontant = 0;
      }

      if (this.pourcentageIndigence) {
        indigenceMontant = (this.pourcentageIndigence * total) / 100;
      } else {
        indigenceMontant = 0;
      }

      // TotalPriseEncharge = assuranceMontant + indigenceMontant;
      this.totalPriseEncharge = assuranceMontant + indigenceMontant;
      total = total - this.totalPriseEncharge;


      if (this.selectedOptionMode == 3) {
        total = total + this.feesFedapay;
      }

      this.momoData.montant = total;

      return total;
    },

    calculateTotalMontantEspece() {
      // cette fonction calcule le montant total identique à calculateTotalMontant()
      let total = 0;
      let assuranceMontant = 0;
      let indigenceMontant = 0;

      for (const form of this.forms) {
        total += form.montant;
      }

      if (this.assurancePourcentage) {
        assuranceMontant = (this.assurancePourcentage * total) / 100;
      } else {
        assuranceMontant = 0;
      }

      if (this.pourcentageIndigence) {
        indigenceMontant = (this.pourcentageIndigence * total) / 100;
      } else {
        indigenceMontant = 0;
      }

      // TotalPriseEncharge = assuranceMontant + indigenceMontant;
      this.totalPriseEncharge = assuranceMontant + indigenceMontant;
      total = total - this.totalPriseEncharge;
      this.momoDataEspece.montant = total;
      return total;
    },

    formatNumber(value) {
      if (typeof value !== "number") {
        return value;
      }

      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    Unite(nombre) {
      let unite;
      switch (nombre) {
        case 0:
          unite = "zéro";
          break;
        case 1:
          unite = "un";
          break;
        case 2:
          unite = "deux";
          break;
        case 3:
          unite = "trois";
          break;
        case 4:
          unite = "quatre";
          break;
        case 5:
          unite = "cinq";
          break;
        case 6:
          unite = "six";
          break;
        case 7:
          unite = "sept";
          break;
        case 8:
          unite = "huit";
          break;
        case 9:
          unite = "neuf";
          break;
      }
      return unite;
    },

    Dizaine(nombre) {
      let dizaine;
      switch (nombre) {
        case 10:
          dizaine = "dix";
          break;
        case 11:
          dizaine = "onze";
          break;
        case 12:
          dizaine = "douze";
          break;
        case 13:
          dizaine = "treize";
          break;
        case 14:
          dizaine = "quatorze";
          break;
        case 15:
          dizaine = "quinze";
          break;
        case 16:
          dizaine = "seize";
          break;
        case 17:
          dizaine = "dix-sept";
          break;
        case 18:
          dizaine = "dix-huit";
          break;
        case 19:
          dizaine = "dix-neuf";
          break;
        case 20:
          dizaine = "vingt";
          break;
        case 30:
          dizaine = "trente";
          break;
        case 40:
          dizaine = "quarante";
          break;
        case 50:
          dizaine = "cinquante";
          break;
        case 60:
          dizaine = "soixante";
          break;
        case 70:
          dizaine = "soixante-dix";
          break;
        case 80:
          dizaine = "quatre-vingt";
          break;
        case 90:
          dizaine = "quatre-vingt-dix";
          break;
      }
      return dizaine;
    },

    NumberToLetter(nombre) {
      let i, j, n, quotient, reste, nb;
      let ch;
      let numberToLetter = "";

      if (nombre.toString().replace(/ /gi, "").length > 15)
        return "dépassement de capacité";
      if (isNaN(nombre.toString().replace(/ /gi, ""))) return "Nombre non valide";

      nb = parseFloat(nombre.toString().replace(/ /gi, ""));
      if (Math.ceil(nb) !== nb) return "Nombre avec virgule non géré.";

      n = nb.toString().length;
      switch (n) {
        case 1:
          numberToLetter = this.Unite(nb);
          break;
        case 2:
          if (nb > 19) {
            quotient = Math.floor(nb / 10);
            reste = nb % 10;
            if (nb < 71 || (nb > 79 && nb < 91)) {
              if (reste === 0) numberToLetter = this.Dizaine(quotient * 10);
              if (reste === 1)
                numberToLetter = this.Dizaine(quotient * 10) + "-et-" + this.Unite(reste);
              if (reste > 1)
                numberToLetter = this.Dizaine(quotient * 10) + "-" + this.Unite(reste);
            } else
              numberToLetter =
                this.Dizaine((quotient - 1) * 10) + "-" + this.Dizaine(10 + reste);
          } else numberToLetter = this.Dizaine(nb);
          break;
        case 3:
          quotient = Math.floor(nb / 100);
          reste = nb % 100;
          if (quotient === 1 && reste === 0) numberToLetter = "cent";
          if (quotient === 1 && reste !== 0)
            numberToLetter = "cent" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste === 0)
            numberToLetter = this.Unite(quotient) + " cents";
          if (quotient > 1 && reste !== 0)
            numberToLetter = this.Unite(quotient) + " cent " + this.NumberToLetter(reste);
          break;
        case 4:
        case 5:
        case 6:
          quotient = Math.floor(nb / 1000);
          reste = nb - quotient * 1000;
          if (quotient === 1 && reste === 0) numberToLetter = "mille";
          if (quotient === 1 && reste !== 0)
            numberToLetter = "mille" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste === 0)
            numberToLetter = this.NumberToLetter(quotient) + " mille";
          if (quotient > 1 && reste !== 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
          break;
        case 7:
        case 8:
        case 9:
          quotient = Math.floor(nb / 1000000);
          reste = nb % 1000000;
          if (quotient === 1 && reste === 0) numberToLetter = "un million";
          if (quotient === 1 && reste !== 0)
            numberToLetter = "un million" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste === 0)
            numberToLetter = this.NumberToLetter(quotient) + " millions";
          if (quotient > 1 && reste !== 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
          break;
        case 10:
        case 11:
        case 12:
          quotient = Math.floor(nb / 1000000000);
          reste = nb - quotient * 1000000000;
          if (quotient === 1 && reste === 0) numberToLetter = "un milliard";
          if (quotient === 1 && reste !== 0)
            numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste === 0)
            numberToLetter = this.NumberToLetter(quotient) + " milliards";
          if (quotient > 1 && reste !== 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
          break;
        case 13:
        case 14:
        case 15:
          quotient = Math.floor(nb / 1000000000000);
          reste = nb - quotient * 1000000000000;
          if (quotient === 1 && reste === 0) numberToLetter = "un billion";
          if (quotient === 1 && reste !== 0)
            numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste === 0)
            numberToLetter = this.NumberToLetter(quotient) + " billions";
          if (quotient > 1 && reste !== 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
          break;
      }

      // Respect de l'accord de "quatre-vingt"
      if (
        numberToLetter.substr(
          numberToLetter.length - "quatre-vingt".length,
          "quatre-vingt".length
        ) === "quatre-vingt"
      ) {
        numberToLetter += "s";
      }

      return numberToLetter;
    },

    convertNumberToLetter(number) {
      let result = this.NumberToLetter(number);
      return result;
    },

    // searchMedicalActs() {
    //   if (this.searchQuery1 && this.searchQuery) {
    //     // Les deux champs sont renseignés, choisissez l'IPP en premier
    //     this.anotherParamSearch = this.searchQuery;
    //   } else if (this.searchQuery && !this.searchQuery1) {
    //     // Le champ IPP/IEP est renseigné et le champ nom est vide
    //     this.anotherParamSearch = this.searchQuery;
    //   } else if (this.searchQuery1 && !this.searchQuery) {
    //     // Le champ nom est renseigné et le champ IPP est vide
    //     this.anotherParamSearch = this.searchQuery1;
    //   } else {
    //     // Aucun des champs n'est renseigné, utilisez une valeur par défaut (par exemple, une chaîne vide)
    //     this.anotherParamSearch = "";
    //     this.totalActe = 0;
    //     this.patientsMove = [];
    //   }

    //   const input = document.getElementById("exampleInputEmail1");
    //   const errorMessage = document.querySelector(
    //     ".form-group #exampleInputEmail1 + .error-message"
    //   );

    //   if (!input.checkValidity()) {
    //     errorMessage.style.display = "block";
    //     return;
    //   } else {
    //     errorMessage.style.display = "none";
    //   }

    //   // Nom prénom
    //   const inputname = document.getElementById("exampleInputName");
    //   const errorMessageName = document.querySelector(
    //     ".form-group #exampleInputName + .error-message"
    //   );

    //   if (this.searchQuery1.trim() == "") {
    //     errorMessageName.style.display = "none"; // Champ vide, le message d'erreur est masqué
    //   } else if (
    //     this.searchQuery1.length > 100 ||
    //     !/^[a-zA-ZÀ-ÿ\s]{2,}$/.test(this.searchQuery1)
    //   ) {
    //     errorMessageName.style.display = "block"; // Erreur détectée
    //   } else {
    //     errorMessageName.style.display = "none"; // La saisie est valide, le message d'erreur est masqué
    //   }

    //   // Effectuez d'abord la recherche en utilisant this.anotherParamSearch (IPP en priorité)
    //   factureService
    //     .getPatientInfo(this.anotherParamSearch)
    //     .then((res) => {
    //       this.patient = res.data.data;
    //       this.patientsMove = res.data.data;
    //       // console.log("Voici le patient qui correspond au mouvement :");
    //       // console.log(this.patientsMove);

    //       // Effectuez la recherche d'actes médicaux

    //       factureService
    //         .getActMovment(this.anotherParamSearch)
    //         .then((res) => {
    //           this.listactes = res.data.data;
    //           // console.log("Voici les actes médicaux correspondants à la saisie :");
    //           // console.log(this.listactes);
    //           this.calculateTotalAmount();
    //         })
    //         .catch((error) => {
    //           console.error(
    //             "Une erreur s'est produite lors de la recherche d'actes médicaux : " +
    //               error
    //           );
    //         });
    //     })
    //     .catch((error) => {
    //       console.error(
    //         "Une erreur s'est produite lors de la recherche du patient (IPP) : " + error
    //       );

    //       // Si aucune donnée n'a été trouvée avec IPP, testez ensuite avec le nom pour le patient
    //       if (this.searchQuery1) {
    //         factureService
    //           .getPatientInfo(this.searchQuery1)
    //           .then((res) => {
    //             this.patientsMove = res.data.data;
    //             console.log("Voici le patient qui correspond au mouvement :");
    //             console.log(res.data.data);

    //             // Effectuez la recherche d'actes médicaux si le patient est trouvé par le nom
    //             factureService
    //               .getActMovment(this.searchQuery1)
    //               .then((res) => {
    //                 this.listactes = res.data.data;
    //                 console.log("Voici les actes médicaux correspondants à la saisie :");
    //                 console.log(this.listactes);
    //               })
    //               .catch((error) => {
    //                 console.error(
    //                   "Une erreur s'est produite lors de la recherche d'actes médicaux : " +
    //                     error
    //                 );
    //               });
    //           })
    //           .catch((error) => {
    //             console.error(
    //               "Une erreur s'est produite lors de la recherche du patient (nom) : " +
    //                 error
    //             );
    //           });
    //       } else {
    //         // Aucun patient n'a été trouvé par le nom non plus
    //         this.listactes = [];
    //       }
    //     });
    // },

    // searchMedicalActs() {

    //   this.anotherParamSearch = this.searchQuery1 && this.searchQuery ? `${this.searchQuery1} ${this.searchQuery}` : this.searchQuery1 || this.searchQuery;

    //   if (this.searchQuery) {

    //     factureService
    //       .getPatientInfo(this.searchQuery)
    //       .then((res) => {
    //         this.patient = res.data.data;
    //         console.log("Voici le patient qui correspond au movement :");

    //         console.log(res.data.data);
    //       })
    //       .catch((error) => {
    //         console.error(
    //           "Une erreur s'est produite lors de la recherche du patient : " + error
    //         );

    //       });

    //     factureService
    //       .getActMovment(this.searchQuery)
    //       .then((res) => {
    //         this.actesMedicals = res.data.data;
    //         console.log("Voici les actes médicaux correspondants à la saisie :");
    //         console.log(this.actesMedicals);
    //       })
    //       .catch((error) => {
    //         console.error(
    //           "Une erreur s'est produite lors de la recherche d'actes médicaux : " + error
    //         );
    //       });
    //   } else {
    //     this.actesMedicals = [];
    //   }
    // },

    // fetchActesMedicals() {
    //   // actsService
    //   //   .getAllActes()
    //   //   .then((res) => {
    //   //     this.actesMedicals = res.data.data;
    //   //   })
    //   factureService
    //     .getActMovment(this.searchQuery)
    //     .then((res) => {
    //       this.actesMedicals = res.data.data;
    //       console.log("voici mes actes venue");
    //       console.log("voici mes actes venue");
    //       console.log("voici mes actes venue");
    //       console.log(this.actesMedicals);
    //     })

    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    fetchActeMedicalDetails(index) {
      const id = this.forms[index].acte;

      console.log("Mon id Acte" + id);
      console.log(id);

      if (id === "SA") {
        // L'utilisateur a sélectionné "Sélectionner un acte médical"
        this.forms[index].readonly = true;
        this.forms[index].code = "";
        this.forms[index].prix = "";
        this.forms[index].montant = "";

        // ...
      }

      if (id === "0") {
        // L'utilisateur a sélectionné "Autres"
        this.forms[index].readonly = false;
        this.forms[index].code = "";
        this.forms[index].prix = "";
        this.forms[index].montant = "";

        // Vérifier si l'objet formulaire existe déjà dans actesSelectionnes

        const existingIndex = this.actesSelectionnes.findIndex(
          (acte) => acte.index === index
        );

        if (existingIndex !== -1) {
          const existingForm = this.actesSelectionnes[existingIndex];

          // Mettre à jour les détails de l'objet formulaire existant
          existingForm.code = "";
          existingForm.prix = this.forms[index].prix;
          existingForm.quantite = this.forms[index].quantite;
          existingForm.montant = this.forms[index].montant;
          // Mettez à jour d'autres propriétés si nécessaire
        } else {
          // Ajouter l'objet formulaire à actesSelectionnes
          this.actesSelectionnes.push({
            index: index,
            id: "0",
            nom: "Autre",
            code: "AAAAA",
            prix: this.forms[index].prix,
            quantite: this.forms[index].quantite,
            montant: this.forms[index].montant,
            // Ajoutez d'autres propriétés si nécessaire
          });
        }
      } else {
        // L'utilisateur a sélectionné un acte médical existant
        this.forms[index].readonly = true;
        this.forms[index].quantite = ""; // Initialiser la quantité à 1

        if (!this.formErrors[index]) {
          this.formErrors[index] = {}; // Créez l'objet formErrors[index] s'il n'existe pas
        }

        // Vérifiez si la propriété acteMedical est définie avant de la définir à false
        if ("acteMedical" in this.formErrors[index]) {
          this.formErrors[index].acteMedical = false;
        }

        factureService
          .getActe(id)
          .then((response) => {
            const acteMedical = response.data.data; // Adapt this to your API response structure
            console.log("Detail acte acteMedical");
            console.log("Detail acte acteMedical");

            console.log(acteMedical);

            // Mettre à jour les détails du formulaire avec les détails de l'acte médical
            this.forms[index].code = acteMedical.code;
            this.forms[index].prix = Math.round(acteMedical.price);
            this.forms[index].montant =
              this.forms[index].quantite * Math.round(acteMedical.price);

            // Vérifier si l'objet formulaire existe déjà dans actesSelectionnes
            const existingIndex = this.actesSelectionnes.findIndex(
              (acte) => acte.index === index
            );
            // console.log(existingIndex);
            // console.log(this.actesSelectionnes);
            if (existingIndex !== -1) {
              const existingForm = this.actesSelectionnes[existingIndex];

              // Mettre à jour les détails de l'objet formulaire existant
              existingForm.nom = acteMedical.designation;
              existingForm.code = acteMedical.code;
              existingForm.prix = Math.round(acteMedical.price);
              existingForm.quantite = this.forms[index].quantite;
              existingForm.montant =
                this.forms[index].quantite * Math.round(acteMedical.price);
            } else {
              // Ajouter l'objet formulaire à actesSelectionne

              this.actesSelectionnes.push({
                index: index,
                id: acteMedical.id,
                nom: acteMedical.designation,
                code: acteMedical.code,
                prix: Math.round(acteMedical.price),
                quantite: this.forms[index].quantite,
                montant: this.forms[index].quantite * Math.round(acteMedical.price),
                // Ajoutez d'autres propriétés si nécessaire
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      // Calculer les totaux après avoir mis à jour les détails de l'acte médical
      this.calculateTotalMontantActe();
      this.calculateTotalMontant();
      this.calculateTotalMontantEspece();
    },

    updateMontantActe(index) {
      const form = this.forms[index];
      form.montant = form.quantite * form.prix;

      this.formErrors[index].quantite = false;
      this.formErrors[index].prix = false;

      if (this.actesSelectionnes[index]) {
        this.actesSelectionnes[index].quantite = form.quantite;
        this.actesSelectionnes[index].montant = form.quantite * form.prix;
        console.log(this.forms[index]);

        if (form.acte == "0") {
          // L'acte est "Autre"
          this.actesSelectionnes[index].nom = form.autre;
          this.actesSelectionnes[index].prix = form.prix;
        } else {
          const acteMedical = this.actesMedicals.find((acte) => acte.id === form.acte);
          if (acteMedical) {
            this.actesSelectionnes[index].nom = acteMedical.designation;
            this.actesSelectionnes[index].prix = Math.round(acteMedical.price);
          }
        }
      } else {
        // L'objet formulaire n'existe pas encore dans actesSelectionnes
        if (form.acte == "0") {
          // L'acte est "Autre"
          this.actesSelectionnes.push({
            code: "-",
            nom: form.autre,
            prix: form.prix,
            quantite: form.quantite,
            montant: form.quantite * form.prix,
            // Ajoutez d'autres propriétés si nécessaire
          });
        } else {
          const acteMedical = this.actesMedicals.find((acte) => acte.id === form.acte);

          if (acteMedical) {
            this.actesSelectionnes.push({
              code: form.code,
              nom: acteMedical.designation,
              prix: Math.round(acteMedical.price),
              quantite: form.quantite,
              montant: form.quantite * Math.round(acteMedical.price),
              // Ajoutez d'autres propriétés si nécessaire
            });
          }
        }
      }

      this.calculateTotalMontantActe();
      this.calculateTotalMontant();
      this.calculateTotalMontantEspece();
    },

    addForm() {
      // this.forms.push({ acte: 'SA', code: '', prix: '', quantite: '', montant: '' });
      this.forms.push({
        acte: "SA",
        quantite: "",
        code: "",
        prix: "",
        montant: "",
        autre: "",
        readonly: true,
      });
      // const newForm = { acte: 'SA', code: '', prix: '', quantite: '', montant: '' };
    },

    deleteForm(index) {
      this.forms.splice(index, 1);
      this.actesSelectionnes.splice(index, 1); // Supprimer l'élément correspondant de actesSelectionnes

      for (let i = index; i < this.actesSelectionnes.length; i++) {
        this.actesSelectionnes[i].index = i;
        // console.log(this.actesSelectionnes[i].index);// compter les index à partir de index
      }

      this.calculateTotalMontantActe();
      this.calculateTotalMontant();
      this.calculateTotalMontantEspece();

      //this.forms.splice(this.forms.length - 1, 1);
    },

    annulerMomo(event) {
      event.stopPropagation();
      this.momoData.statut = "FAILED";
    },


    validerPayement() {
      
     console.log( this.cashRegisterCashierCurrent.statut);
      if(this.cashRegisterCashierCurrent.statut == 1){

        this.$toast.error("La caisse est fermée !", {
          position: "top-right",
          timeout: 3000,
          style: {
            background: "#ff0000", // Couleur de fond rouge pour l'erreur 
            color: "#fff", // Couleur du texte
            fontWeight: "bold",
          },
        });

        return ; 

      }else{

      if (this.selectedOptionMode == 1) {

        this.createFacture();
        this.ShowFacture();

       } 

      if (this.selectedOptionMode == 2) {
        // this.validerMomo();
        this.kkiapay();

      }

      if (this.selectedOptionMode == 3) {
        this.kkiapay();

        // $("button#pay-btn").click(function (event) {
        //   event.stopPropagation();
        // });

        // $("button#pay-btn").click();
      }

      if (this.selectedOptionMode == 4) {
        this.kkiapay();
      }

      if (this.selectedOptionMode == 5) {
        this.kkiapay();
      }

      if (this.selectedOptionMode == 6) {
        this.kkiapay();
      }

      // if (this.selectedOptionMode == 7) {
      //   console.log("my tresopay");
      //   this.tresorPay();
      // }

      
      if (this.selectedOptionMode == 8) {

        this.createFacture();
        this.ShowFacture();
       } 


    }
    },

    createFacture() {
      const factures = this.medicalActDetails.map((acte) => ({
        cash_registers_id: this.cashRegisterCashierCurrent.cash_registers_id,
        movments_id: acte.movments_id,
        mode_payements_id: this.selectedOptionMode,
        is_synced: 0,
        acte_medical_id: acte.id,
        user_id: null,
        centre_id: null,
        code: acte.code,
        autre: null,
        prix: parseInt(acte.medical_acts_price), // Convertir en entier
        quantite: acte.medical_acts_qte,
        montant: acte.medical_acts_qte * parseInt(acte.medical_acts_price),
      }));
      console.log(factures);

      factureService
        .requestFacture({ factures: factures }) // Utilisez la clé "factures"
        .then((response) => {
          // Traitement de la réponse
          const facturesdata = response.data.data;
          this.referenceFacture = response.data.reference;
          console.log(this.referenceFacture);
          this.statutCashier =  response.data.statutCash;
          console.log(this.statutCashier);
          this.generateQRCode(this.referenceFacture);
          this.selectedRow = -1;
          this.medicalActDetails = []; 


          // this.validateData(); 
        })
        .catch((error) => {
            console.log(error.response);
            
        });
    },



//     createFacture() {
//   return new Promise((resolve, reject) => {
//     const factures = this.medicalActDetails.map((acte) => ({
//       cash_registers_id: 1,
//       movments_id: acte.movments_id,
//       mode_payements_id: this.selectedOptionMode,
//       is_synced: 0,
//       acte_medical_id: acte.id,
//       user_id: null,
//       centre_id: null,
//       code: acte.code,
//       autre: null,
//       prix: parseInt(acte.medical_acts_price), // Convertir en entier
//       quantite: acte.medical_acts_qte,
//       montant: acte.medical_acts_qte * parseInt(acte.medical_acts_price),
//     }));
//     console.log(factures);

//     factureService
//       .requestFacture({ factures: factures })
//       .then((response) => {
//         // Traitement de la réponse
//         const facturesdata = response.data.data;
//         this.referenceFacture = response.data.reference;
//         console.log(this.referenceFacture);
//         this.statutCashier = response.data.statutCash;
//         console.log(this.statutCashier);
//         this.generateQRCode(this.referenceFacture);
//         resolve(); // Résoudre la promesse

//         // this.validateData();
//       })
//       .catch((error) => {
//         console.log(error.response);
//         this.statutCashier = 1;

//         if (error.response.data.error === true) {
//           console.log(error.response.status);
//           console.log("pourquoi");
//           console.log(this.statutCashier);

//           this.$toast.error("La caisse est fermée", {
//             position: "top-right",
//             timeout: 3000,
//             style: {
//               background: "#ff0000", // Couleur de fond rouge pour l'erreur
//               color: "#fff", // Couleur du texte
//               fontWeight: "bold",
//             },
//           });

//           reject(); // Rejeter la promesse en cas d'erreur
//         }
//       });
//   });
// },



    validerEspece(payementId) {
      const credentials = {
        mode_payements_id: this.selectedOptionMode,
        payement_id: payementId,
        montant: this.momoDataEspece.montant,
      };

      especeService
        .requestEspece(credentials, payementId)
        .then((response) => {
          const montant = response.data.data.montant;
          console.log(montant);
        })

        .catch((error) => {
          console.error(error);
        });
    },

    validerMomo() {
      this.formErrors = {};
      this.formErrors.nom = false;
      this.formErrors.prenom = false;
      this.formErrors.telephone = false;

      // Vérifier les champs obligatoires

      if (this.selectedOptionMode == 2 && this.momoData.nom == "") {
        this.formErrors.nom = true;
        return; // Sortir de la fonction en cas d'erreur
      }

      if (this.selectedOptionMode == 2 && this.momoData.prenom == "") {
        this.formErrors.prenom = true;
        return; // Sortir de la fonction en cas d'erreur
      }

      if (this.selectedOptionMode == 2 && this.momoData.telephone == "") {
        this.formErrors.telephone = true;
        return; // Sortir de la fonction en cas d'erreur
      }

      console.log("statut");
      // console.log(this.momoData.statut);

      $("#confirmationMOMO").modal("show");

      setTimeout(() => {
        if (this.momoData.statut === "PENDING") {
          this.momoData.statut = "SUCCESSFUL";
        }

        if (this.momoData.statut === "SUCCESSFUL") {

          $("#confirmationMOMO").modal("show");

          this.createFacture();
          this.showFacture();

        }
      }, 6000);
    },

    checkreadonly(form) {
      // console.log(form.acte);
      if (form.acte === "0") {
        // if (this.forms.some((form) => form.acte === "autre")) {
        form.readonly = false;
      } else {
        form.readonly = true;
      }
    },

    showConfirmationModal() {
      // const verifyAffectTerminal = this.affectterminals.find(
      //   (affectterminal) => affectterminal.user_id == this.user.id
      // );

      // if (!verifyAffectTerminal) {
      //   this.$toast.error("Vous n'êtes pas affecté à un terminal !", {
      //     position: "top-right",
      //     timeout: 3000,
      //     style: {
      //       background: "#ff0000", // Couleur de fond rouge pour l'erreur
      //       color: "#fff", // Couleur du texte
      //       fontWeight: "bold",
      //     },
      //   });

      //   return;
      // }

      $("#confirmationModal").modal("show"); // affciher le modal
    },

    validateData() {
      // Code pour valider les données dans la base de données

      $("#confirmationModal").modal("hide");
      $("#confirmationModalpay").modal("show");
    },

    validationEnCour() {
      // Code pour valider les données dans la base de données

      $("#paiementencour").modal("show");
    },

    ShowFacture() {
      // Code pour valider les données dans la base de données

      $("#confirmationModal").modal("hide");
      $("#confirmationModalpay").modal("hide");
      $("#exampleModal").modal("show");
      // $("#confirmationModalpay").modal("hide");
    
    },

    // printModal() {
    //   const modalContent = this.$refs.modalContent;
    //   const originalContents = document.body.innerHTML;

    //   // Temporairement remplacer le contenu de la page par le contenu du modal
    //   document.body.innerHTML = modalContent.innerHTML;

    //   // Imprimer le contenu
    //   window.print();

    //   // Restaurer le contenu original de la page
    //   document.body.innerHTML = originalContents;

    //   $("#exampleModal").modal("hide");
    // },

    printModal() {
  // Ouvrez le modal
  $("#showDetail").modal("show");

  // Assurez-vous que la fenêtre de prévisualisation ne s'ouvre qu'une fois
  if (typeof this.printWindow === 'undefined' || this.printWindow.closed) {
    // Sélectionnez l'élément que vous voulez imprimer (la partie spécifique avec l'ID "print-section")
    const printContent = document.getElementById("print-section");

    // Créez une fenêtre de prévisualisation pour l'impression
    this.printWindow = window.open('', '', 'width=600,height=600');

    // Attendez un court délai pour que le modal soit entièrement affiché
    setTimeout(function() {
      // Insérez le contenu HTML de la page d'origine dans la fenêtre de prévisualisation
      this.printWindow.document.write('<html><head><title>Impression</title>');

      // Incluez les balises <link> pour les fichiers Bootstrap CDN
      this.printWindow.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">');
      this.printWindow.document.write('<link rel="stylesheet" href="../../../../node_modules/bootstrap-vue/dist/bootstrap-vue.css">');

      // Incluez une balise <link> pour votre fichier CSS externe
      this.printWindow.document.write('<link rel="stylesheet" type="text/css" href="../../../../assets/scss/style.css">');

      // Ajoutez des styles CSS personnalisés pour les en-têtes de tableau
      this.printWindow.document.write('<style>.table th { background-color: rgb(213, 219, 218) !important; padding: 5px; }</style>');

      // Ajoutez des styles CSS pour réduire les marges internes (padding) des cellules du tableau
      this.printWindow.document.write('<style>.table th td { padding: 2px; }</style>');

      this.printWindow.document.write('</head><body>');
      this.printWindow.document.write(printContent.innerHTML);
      this.printWindow.document.write('</body></html>');
      this.printWindow.document.close();

      // Attendez que le contenu soit chargé avant d'imprimer
      this.printWindow.onload = function () {
        this.printWindow.print();
        this.printWindow.close();
      }.bind(this);

      // Fermez le modal
      $("#showDetail").modal("hide");
    }.bind(this), 1000); // Ajustez la durée en fonction de votre besoin
  }
},


     
     
  //    printModal() {
     
  //     const modalContent = this.$refs.modalContent;
      
  //     const pdfOptions = {
  //       margin: 10, // Marge en pixels
  //       filename: 'modal.pdf', // Nom du fichier PDF
  //       image: { type: 'jpeg', quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  //     };

  //     html2pdf()
  //       .from(modalContent)
  //       .set(pdfOptions)
  //       .outputPdf(pdf => {
  //         pdf.output('datauristring'); // Pour afficher le PDF dans un nouvel onglet
  //       });
  // },



  },

  filters: {

    formatDate(value) {
      return format(value, "dd/MM/yyyy HH:mm:ss");
    },

  },

  watch: {
    searchTerm: function (newSearchTerm, oldSearchTerm) {
      // Si le champ de recherche est vide, appelez getListAndSearchMovments
      if (newSearchTerm === "") {
        this.getListAndSearchMovments();
      }
    },
  },

  mounted() {
    this.getListAndSearchMovments();
    this.getListMovment();

    // this.fetchActesMedicals();
    $("#confirmationMOMO").on("hidden.bs.modal", () => {
      this.momoData.statut = "PENDING";
    });

    this.fetchPatients();
    // this.fetchModePayements();
  },

  beforeDestroy() {
  },
};
</script>

<style scoped>
@import "../../../../node_modules/bootstrap-vue/dist/bootstrap-vue.css";

.col-sm-6 p {
  margin-top: 0;
  /* Supprime la marge supérieure des paragraphes */
  margin-bottom: 2px !important;
  /* Réduit la marge inférieure des paragraphes */
}

.custom-width {
  width: 200px !important;
  /* Ajustez la largeur selon vos besoins */
}

.custom-margin-bottom {
  margin-bottom: -30px !important;
  /* Ajustez la valeur de la marge inférieure selon vos besoins */
}

.custom-padding {
  padding: 5px !important;
  /* Ajustez la valeur du padding selon vos besoins */
}

/* .custom-border-height {
  border: 1px solid #000 !important;
} */

.search-bar {
  display: flex;
  background-color: #fff;
  /* Couleur de fond de la barre de recherche */
  padding: 10px;
  /* Espacement interne */
  border: 1px solid #ccc;
  /* Bordure */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Effet d'ombre */
  border-radius: 6px !important;
  align-items: center;
  /* Alignement vertical au centre */
  height: 50px !important;
  /* Hauteur réduite */
}

.search-bar {
  display: flex;
  background-color: #fff;
  /* Couleur de fond de la barre de recherche */
  padding: 10px;
  /* Espacement interne */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Effet d'ombre */
  border-radius: 5px;
  /* Coins arrondis */
  align-items: center;
  /* Alignement vertical au centre */
}

.search-bar input {
  flex: 1 !important;
  /* Largeur flexible */
  outline: none !important;
  font-size: 16px !important;
  height: 40px !important;
  /* Hauteur ajustée pour correspondre à la hauteur du bouton */
  border: none !important;
  /* Supprimer la bordure */
}

.search-bar button {
  background-color: #0b5d3f;
  /* Couleur verte */
  color: #fff;
  border: none !important;
  padding: 0 !important;
  /* Pas de padding sur le bouton */
  width: 40px !important;
  /* Largeur ajustée pour correspondre à la hauteur du champ de recherche */
  height: 40px !important;
  /* Hauteur ajustée pour correspondre à la hauteur du champ de recherche */
  border-radius: 0 5px 5px 0 !important;
  /* Coins arrondis à gauche */
  cursor: pointer !important;
  transition: background-color 0.3s;
}

.search-bar button:hover {
  background-color: #5c695d;
  /* Couleur verte plus foncée au survol */
}

.patient-info {
  margin-right: 20px !important;
  font-weight: 500 !important;
  font-family: "Montserrat" !important;
  /* Ajustez la marge selon vos besoins */
}

.text-red {
  color: red !important;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.modal--fullscreen {
  max-width: 90vw;
}

select {
  cursor: pointer;
  height: 43px !important;
}

input[type="radio"].demo6 {
  display: none;
}

input[type="radio"].demo6+label {
  position: relative;
  padding-left: 1.3rem;
}

input[type="radio"].demo6+label::before,
input[type="radio"].demo6+label::after {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  border-radius: 1rem;
}

input[type="radio"].demo6+label::before {
  bottom: 0;
  left: 0;
  border: 1px solid #ccc;
  background-color: #eee;
  width: 1rem;
  height: 1rem;
}

input[type="radio"].demo6+label::after {
  bottom: 3px;
  left: 3px;
  width: calc(1rem - 6px);
  height: calc(1rem - 6px);
}

input[type="radio"].demo6:checked+label::after {
  background-color: #45c28e;
}

input[type="checkbox"].demo2 {
  display: none;
}

input[type="checkbox"].demo2+label::before {
  content: "";
  border: 1px solid #45c28e;
  padding: 0 0.6rem;
  margin-right: 0.3rem;
}

input[type="checkbox"].demo2:checked+label::before {
  background-color: #45c28e;
}

fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}

th,
td {
  text-align: center;
}

th {
  background-color: #d6dbd6;
  border: 1px solid #dee2e6;
  font-family: Montserrat !important;
}

.container-fluid {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

h2,
h4 {
  font-weight: bold;
  margin: 0;
  font-family: Montserrat !important;
}

.mb-4 {
  margin-bottom: 2rem;
}

img {
  max-width: 100%;
}

.selected {
  color: white !important;
  background-color: hsl(159, 38%, 25%);
  /* Couleur de fond rouge pour la ligne sélectionnée */
  cursor: pointer;
  /* Curseur pointeur */
}

.row-hover:hover {
  cursor: pointer !important;
  background-color: hsl(159, 38%, 25%);
  color: white;

  /* Curseur pointeur au survol des lignes du tableau */
}

table {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  font-family: Montserrat !important;
}

input,
select {
  font-weight: bold !important;
  color: rgb(78, 77, 77) !important;
}

select option {
  font-weight: bold !important;
  color: rgb(78, 77, 77) !important;
}

table td,
table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-family: "Montserrat" !important;
}

table td {
  font-weight: 500 !important;
}

table th {
  font-weight: bold;
  font-family: "Montserrat" !important;
}

table tbody+tbody {
  border-top: 2px solid #e4eaea;
}

.table-bordered {
  border: 1px solid #b6b9bb;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #b6b9bb;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.text-right {
  text-align: right;
}

.mt-5 {
  margin-top: 3rem;
}

.text-danger {
  color: #dc3545;
}
</style>
